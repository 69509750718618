<template>
  <div class="login_container">
    <IndexInner v-if="defaultPage"></IndexInner>
  </div>
</template>
<script>
import { translateTitle } from "@/assets/js/i18n";
import IndexInner from "@/pages/login/index-inner.vue";
export default {
  mixins: [],

  data() {
    return {
      defaultPage: true,
    };
  },
  components: { IndexInner },
  computed: {
    // ...mapGetters({
    //   darkMode: "user/darkMode"
    // })
  },
  created() {},
  beforeDestroy() {},
  destroyed() {},
  mounted() {},
  methods: {
    translateTitle,
  },
};
</script>
<style lang="scss"></style>
